// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  //App
  --home-app-background-color:#ffffff !important;
  --find-my-location-btn-background:#686262 !important;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #b1adad;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  //  custom
  --ion-color-white: #fff;
  --ion-color-sec-white: #f3f1f1;
  --ion-color-thr-white: #d0cece;
  --ion-color-fou-white: #e6e6e6;
  --ion-color-fiv-white: #d2d2d2;
  --ion-color-six-white: #c9c8c8;

  --ion-color-custom-sec-red: #ff071c;
  --ion-color-custom-six-red: #e31818;
  --ion-color-custom-sev-red: #d41313;
  --ion-color-custom-eig-red: #da2f11;
  --ion-color-custom-nin-red: #fb3939;
  --ion-color-custom-ten-red: #f93b5e;

  --ion-color-custom-black: #000000;
  --ion-color-custom-sec-black: #000000;
  --ion-color-custom-gray: #4f4f4ffd;
  --ion-color-custom-sec-gray: #3e3e3e;
  --ion-color-custom-thr-gray: #b3b3b3;
  --ion-color-custom-fou-gray: #828282;
  --ion-color-custom-medium-gray: #aaa;

  --ion-color-custom-green: #27ae60;
  --ion-color-custom-sec-green: #3c7c36;

  --ion-color-custom-yellow: #ffa500;

  --ion-color-custom-footer: #000000;
  --ion-color-custom-header: #000000;
  --ion-color-custom-fou-red: rgb(255, 255, 255); // menu item color
  --ion-color-custom-fif-red: #b00d0d; // button color
  --ion-color-custom-red: #ff001c; /// order now buttons color
  --ion-color-custom-cart-btn: #a5c02b;
  --ion-color-custom-thr-red: #d72c1e; // text color

  --alert-header-text-color : #fff;

  // brushstoke in web menu page
  --brushstoke-color : #b00d0d;
  // order now button arrows
  --color-active: #ffffff;
  --color-inactive: #c1091b;
  // loading spinner colors
  --loading-spinner-rgb: 255, 7, 28;
  // side menu
  --side-menu-bakcground: #ff071c;

  --radio-color : #ff071c;

  --select-list-primary: #192978;

  --favourite-restaurant-icon: #ffbc00;

  // --------------------------- WEB VERSION
  --common-used-red-color: #ff4b5a;
  --cookie-banner-background: #000;
  --cookie-banner-text: #fff;
  --cookie-banner-dismiss-text: rgb(255, 255, 255);
  --cookie-banner-accept-btn-background: #ff071c; // rgb(241, 214, 0);
  --cookie-banner-accept-btn-background-hover: #b00d0d;
  --cookie-banner-accept-btn-text: #fff; // rgb(0, 0, 0);
}
